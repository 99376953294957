import Bio from 'components/common/Bio';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import GlobalStyle from 'components/common/GlobalStyles';
import Header from 'components/common/Header';
import ResumeIcon from '../static/images/ResumeMenu.svg';
import PortfolioIcon from '../static/images/PortfolioMenu.svg';

const AboutWrapper = styled.header`
  display: flex;
  flex-direction: column;
  color: #282828;
  color: black;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  grid-gap: 60px;

  .about__menu {
    border-bottom: 4px solid transparent;

    :hover {
      border-bottom: 4px solid black;
    }
  }
`;

const Resume = styled.div`
  display: flex;
  justify-content: center;

  span:hover {
    text-decoration: underline;
  }
`;

const About = () => {
  return (
    <AboutWrapper>
      <GlobalStyle />
      <Header />
      <Bio />
      <Menu>
        <Resume>
          <Link to={'/resume/'}>
            <img src={ResumeIcon} alt="ResumeIcon" className="about__menu" />
          </Link>
        </Resume>
        <a href="https://westhyun.github.io/portfolio/" target={'_blank'}>
          <img
            src={PortfolioIcon}
            alt="PortfolioIcon"
            className="about__menu"
          />
        </a>
      </Menu>
    </AboutWrapper>
  );
};
export default About;
